import React, { useEffect } from "react"
import Head from 'next/head';
import { AppProps } from 'next/app';
import { ThemeProvider } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider, EmotionCache } from '@emotion/react';
import theme from '../lib/theme';
import createEmotionCache from '../lib/createEmotionCache';
import { ApolloProvider } from '@apollo/client'
import apolloClient from '../lib/apollo'
import '../styles/app.css';
import Script from "next/script"
import * as gtag from "../lib/gtag";
import { useRouter } from "next/router";
import getConfig from 'next/config'
import { SessionProvider } from "next-auth/react"

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps: { session, ...pageProps } } = props;
  const { publicRuntimeConfig } = getConfig()

  const router = useRouter();
  if (publicRuntimeConfig.googleAnalyticsId != '') {
    useEffect(() => {
      const handleRouteChange = (url) => {
        gtag.pageview(url);
      };
      router.events.on("routeChangeComplete", handleRouteChange);
      return () => {
        router.events.off("routeChangeComplete", handleRouteChange);
      };
    }, [router.events]);
  }

  return (
    <SessionProvider session={session}>
      <ApolloProvider client={apolloClient}>
        <CacheProvider value={emotionCache}>
          <Head>
            <title>Staff Portal</title>
            <meta name="viewport" content="initial-scale=1, width=device-width" />
          </Head>
          {/* Global Site Tag (gtag.js) - Google Analytics */}
          {publicRuntimeConfig.googleAnalyticsId != '' ? <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${publicRuntimeConfig.googleAnalyticsId}`}
            /> : null}
          {publicRuntimeConfig.googleAnalyticsId != '' ? <Script
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${publicRuntimeConfig.googleAnalyticsId}', {
                  page_path: window.location.pathname,
                });
              `,
            }}
          /> : null }
          <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <Component {...pageProps} />
          </ThemeProvider>
        </CacheProvider>
      </ApolloProvider>
    </SessionProvider>
  );
}
