import { ApolloClient, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: createUploadLink({ uri: publicRuntimeConfig.rootUrl + '/api/graphql' })
})

export default apolloClient
